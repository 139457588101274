<template>
  <div>
    <NavBar style="background-color: #fff">
      <div slot="title">系统通知</div>
    </NavBar>
    <div class="layout-page">
      <h3>{{ noticeInfo.title }}</h3>
      <div>
        <span class="gray-color">钜宏家庭短号</span>
      </div>
      <p class="gray-color">{{ noticeInfo.created_date }}</p>
      <p class="details" v-html="noticeInfo.content"></p>
    </div>
  </div>
</template>

<script>
import NavBar from "@/layout/nav-bar/nav-bar";
import { newsNotice } from "@/api/news";
export default {
  name: "inform-details",
  components: {
    NavBar,
  },
  data() {
    return {
      id: this.$route.query.id === undefined ? 0 : this.$route.query.id,
      noticeInfo: { id: 0 },
    };
  },
  mounted() {
    this.newsNotice();
  },
  methods: {
    async newsNotice() {
      const ret = await newsNotice({ id: this.id });
      if (ret.code * 1 == 1) {
        this.noticeInfo = ret.data;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.layout-page {
  padding: 12px;
  background-color: #fff;
  min-height: 90vh;
  font-size: 16px;
  h3 {
    font-weight: 500;
    font-size: 18px;
  }
}
.gray-color {
  color: #aaaaaa;
  font-size: 14px;
}
.blue-color {
  color: #0040ed;
  margin: 10px;
}
</style>
